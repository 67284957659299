import React from "react";
import SectionHeading from "../SectionHeading/SectionHeading";
import ServicesSlider from "../ServicesSlider/ServicesSlider";

function Services() {
  return (
    <section id="services" className="services">
      <SectionHeading>
        SΣRVICES{" "}
        <svg
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M24.4303 16.1944C24.479 15.8044 24.5156 15.4144 24.5156 15C24.5156 14.5856 24.479 14.1956 24.4303 13.8056L27.0018 11.7947C27.2334 11.6119 27.2943 11.2828 27.1481 11.0147L24.7106 6.79781C24.5643 6.52969 24.2353 6.43219 23.9671 6.52969L20.9325 7.74844C20.2987 7.26094 19.6162 6.85875 18.8728 6.55406L18.4096 3.32438C18.3731 3.03188 18.1171 2.8125 17.8125 2.8125H12.9375C12.6328 2.8125 12.3768 3.03188 12.3403 3.32438L11.8771 6.55406C11.1337 6.85875 10.4512 7.27313 9.81746 7.74844L6.78277 6.52969C6.50246 6.42 6.18558 6.52969 6.03933 6.79781L3.60183 11.0147C3.44339 11.2828 3.51652 11.6119 3.74808 11.7947L6.31964 13.8056C6.27089 14.1956 6.23433 14.5978 6.23433 15C6.23433 15.4022 6.27089 15.8044 6.31964 16.1944L3.74808 18.2053C3.51652 18.3881 3.45558 18.7172 3.60183 18.9853L6.03933 23.2022C6.18558 23.4703 6.51464 23.5678 6.78277 23.4703L9.81746 22.2516C10.4512 22.7391 11.1337 23.1413 11.8771 23.4459L12.3403 26.6756C12.3768 26.9681 12.6328 27.1875 12.9375 27.1875H17.8125C18.1171 27.1875 18.3731 26.9681 18.4096 26.6756L18.8728 23.4459C19.6162 23.1413 20.2987 22.7269 20.9325 22.2516L23.9671 23.4703C24.2475 23.58 24.5643 23.4703 24.7106 23.2022L27.1481 18.9853C27.2943 18.7172 27.2334 18.3881 27.0018 18.2053L24.4303 16.1944ZM15.375 19.2656C13.0228 19.2656 11.1093 17.3522 11.1093 15C11.1093 12.6478 13.0228 10.7344 15.375 10.7344C17.7271 10.7344 19.6406 12.6478 19.6406 15C19.6406 17.3522 17.7271 19.2656 15.375 19.2656Z"
            fill="white"
          />
        </svg>
      </SectionHeading>

      <ServicesSlider />

      {/* <svg
        className="watermark"
        width="1114"
        height="631"
        viewBox="0 0 1114 631"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M140 324L202.354 451.5H77.6462L140 324Z"
          fill="#FFFD75"
          fillOpacity="0.07"
        />
        <path
          d="M209.238 449.52L145.956 322.479L270.66 321.569L209.238 449.52Z"
          fill="#FFFD75"
          fillOpacity="0.07"
        />
        <path
          d="M277.996 322.784L341.734 449.597L217.034 450.955L277.996 322.784Z"
          fill="#FFFD75"
          fillOpacity="0.07"
        />
        <path
          d="M559 319L621.354 446.5H496.646L559 319Z"
          fill="#FFFD75"
          fillOpacity="0.07"
        />
        <path
          d="M628.238 444.52L564.956 317.479L689.66 316.569L628.238 444.52Z"
          fill="#FFFD75"
          fillOpacity="0.07"
        />
        <path
          d="M696.996 317.784L760.734 444.597L636.034 445.955L696.996 317.784Z"
          fill="#FFFD75"
          fillOpacity="0.07"
        />
        <path
          d="M487.046 447.086L424.539 319.661L549.247 319.512L487.046 447.086Z"
          fill="#FFFD75"
          fillOpacity="0.07"
        />
        <path
          d="M417.658 321.649L481.092 448.615L356.389 449.674L417.658 321.649Z"
          fill="#FFFD75"
          fillOpacity="0.07"
        />
        <path
          d="M349.052 448.467L285.162 321.73L409.86 320.223L349.052 448.467Z"
          fill="#FFFD75"
          fillOpacity="0.07"
        />
        <path
          d="M906.046 442.086L843.539 314.661L968.247 314.512L906.046 442.086Z"
          fill="#FFFD75"
          fillOpacity="0.07"
        />
        <path
          d="M836.658 316.649L900.092 443.615L775.389 444.674L836.658 316.649Z"
          fill="#FFFD75"
          fillOpacity="0.07"
        />
        <path
          d="M768.052 443.467L704.162 316.73L828.86 315.223L768.052 443.467Z"
          fill="#FFFD75"
          fillOpacity="0.07"
        />
        <path
          d="M72 460.5L134.354 588H9.64617L72 460.5Z"
          fill="#FFFD75"
          fillOpacity="0.07"
        />
        <path
          d="M141.238 586.02L77.9559 458.979L202.66 458.069L141.238 586.02Z"
          fill="#FFFD75"
          fillOpacity="0.07"
        />
        <path
          d="M209.996 459.284L273.734 586.097L149.034 587.455L209.996 459.284Z"
          fill="#FFFD75"
          fillOpacity="0.07"
        />
        <path
          d="M491 455.5L553.354 583H428.646L491 455.5Z"
          fill="#FFFD75"
          fillOpacity="0.07"
        />
        <path
          d="M560.238 581.02L496.956 453.979L621.66 453.069L560.238 581.02Z"
          fill="#FFFD75"
          fillOpacity="0.07"
        />
        <path
          d="M628.996 454.284L692.734 581.097L568.034 582.455L628.996 454.284Z"
          fill="#FFFD75"
          fillOpacity="0.07"
        />
        <path
          d="M419.046 583.586L356.539 456.161L481.247 456.012L419.046 583.586Z"
          fill="#FFFD75"
          fillOpacity="0.07"
        />
        <path
          d="M349.658 458.149L413.092 585.115L288.389 586.174L349.658 458.149Z"
          fill="#FFFD75"
          fillOpacity="0.07"
        />
        <path
          d="M281.052 584.967L217.162 458.23L341.86 456.723L281.052 584.967Z"
          fill="#FFFD75"
          fillOpacity="0.07"
        />
        <path
          d="M838.046 578.586L775.539 451.161L900.247 451.012L838.046 578.586Z"
          fill="#FFFD75"
          fillOpacity="0.07"
        />
        <path
          d="M768.658 453.149L832.092 580.115L707.389 581.174L768.658 453.149Z"
          fill="#FFFD75"
          fillOpacity="0.07"
        />
        <path
          d="M700.052 579.967L636.162 453.23L760.86 451.723L700.052 579.967Z"
          fill="#FFFD75"
          fillOpacity="0.07"
        />
        <path
          d="M275 52L337.354 179.5H212.646L275 52Z"
          fill="#FFFD75"
          fillOpacity="0.07"
        />
        <path
          d="M344.238 177.52L280.956 50.4785L405.66 49.5692L344.238 177.52Z"
          fill="#FFFD75"
          fillOpacity="0.07"
        />
        <path
          d="M412.996 50.7839L476.734 177.597L352.034 178.955L412.996 50.7839Z"
          fill="#FFFD75"
          fillOpacity="0.07"
        />
        <path
          d="M694 47L756.354 174.5H631.646L694 47Z"
          fill="#FFFD75"
          fillOpacity="0.07"
        />
        <path
          d="M763.238 172.52L699.956 45.4785L824.66 44.5692L763.238 172.52Z"
          fill="#FFFD75"
          fillOpacity="0.07"
        />
        <path
          d="M831.996 45.7839L895.734 172.597L771.034 173.955L831.996 45.7839Z"
          fill="#FFFD75"
          fillOpacity="0.07"
        />
        <path
          d="M622.046 175.086L559.539 47.6609L684.247 47.5115L622.046 175.086Z"
          fill="#FFFD75"
          fillOpacity="0.07"
        />
        <path
          d="M552.658 49.6488L616.092 176.615L491.389 177.674L552.658 49.6488Z"
          fill="#FFFD75"
          fillOpacity="0.07"
        />
        <path
          d="M484.052 176.467L420.162 49.7304L544.86 48.2232L484.052 176.467Z"
          fill="#FFFD75"
          fillOpacity="0.07"
        />
        <path
          d="M1041.05 170.086L978.539 42.6609L1103.25 42.5115L1041.05 170.086Z"
          fill="#FFFD75"
          fillOpacity="0.07"
        />
        <path
          d="M971.658 44.6488L1035.09 171.615L910.389 172.674L971.658 44.6488Z"
          fill="#FFFD75"
          fillOpacity="0.07"
        />
        <path
          d="M903.052 171.467L839.162 44.7304L963.86 43.2232L903.052 171.467Z"
          fill="#FFFD75"
          fillOpacity="0.07"
        />
        <path
          d="M207 188.5L269.354 316H144.646L207 188.5Z"
          fill="#FFFD75"
          fillOpacity="0.07"
        />
        <path
          d="M276.238 314.02L212.956 186.979L337.66 186.069L276.238 314.02Z"
          fill="#FFFD75"
          fillOpacity="0.07"
        />
        <path
          d="M344.996 187.284L408.734 314.097L284.034 315.455L344.996 187.284Z"
          fill="#FFFD75"
          fillOpacity="0.07"
        />
        <path
          d="M626 183.5L688.354 311H563.646L626 183.5Z"
          fill="#FFFD75"
          fillOpacity="0.07"
        />
        <path
          d="M695.238 309.02L631.956 181.979L756.66 181.069L695.238 309.02Z"
          fill="#FFFD75"
          fillOpacity="0.07"
        />
        <path
          d="M763.996 182.284L827.734 309.097L703.034 310.455L763.996 182.284Z"
          fill="#FFFD75"
          fillOpacity="0.07"
        />
        <path
          d="M554.046 311.586L491.539 184.161L616.247 184.012L554.046 311.586Z"
          fill="#FFFD75"
          fillOpacity="0.07"
        />
        <path
          d="M484.658 186.149L548.092 313.115L423.389 314.174L484.658 186.149Z"
          fill="#FFFD75"
          fillOpacity="0.07"
        />
        <path
          d="M416.052 312.967L352.162 186.23L476.86 184.723L416.052 312.967Z"
          fill="#FFFD75"
          fillOpacity="0.07"
        />
        <path
          d="M973.046 306.586L910.539 179.161L1035.25 179.012L973.046 306.586Z"
          fill="#FFFD75"
          fillOpacity="0.07"
        />
        <path
          d="M903.658 181.149L967.092 308.115L842.389 309.174L903.658 181.149Z"
          fill="#FFFD75"
          fillOpacity="0.07"
        />
        <path
          d="M835.052 307.967L771.162 181.23L895.86 179.723L835.052 307.967Z"
          fill="#FFFD75"
          fillOpacity="0.07"
        />
      </svg> */}
    </section>
  );
}

export default Services;
