import React, { useState, useEffect } from "react";
import ServicesItem from "../ServicesItem/ServicesItem";
import ServicesListItem from "../ServicesListItem/ServicesListItem";
import style from "./index.module.css";

function ServicesSlider() {
  const [services, setServices] = useState([
    {
      title: "Full Stack Development",
      body: `I'm a seasoned backend developer with experience in startup, hackathon, and personal projects. While I enjoy full-stack development, I'm driven to expand my knowledge and skills. I'm committed to staying up-to-date with emerging trends to create innovative solutions and drive progress in the industry.`,
    },
    {
      title: "Web3 development",
      body: `Using my expertise, I have successfully built and deployed blockchain projects on both testnet and mainnet, culminating in the development of a crypto savings platform. However, I realized that my interests lie beyond the realm of network limitations.`,
    },
    {
      title: "Embedded C Engineer",
      body: `Before discovering programming, I was deeply entrenched in creating hardware projects from scratch using raw materials and electronics components. To date, I have generated over 100 settled ideas, completed 50+ projects using hardware, and developed more than 25 scripts. However, my passion for coding was ignited by the ability to bring my ideas to life and create something truly innovative.

      My natural inclination towards design and building, coupled with my love for technology, has allowed me to marry these two passions and create inventions that aid individuals in their daily activities. Although my primary focus is on coding, I continue to engage in hardware projects and backend development as a side hustle to explore all aspects of my skill set.`,
    },
  ]);

  const [activeIndex, setActiveIndex] = useState(0);
  const [direction, setDirection] = useState("Y");

  const updateDirection = (width) => {
    setDirection((prevDirection) => {
      if (width <= 800 && prevDirection === "Y") {
        return "X";
      } else if (width >= 820 && prevDirection === "X") {
        return "Y";
      } else {
        return prevDirection;
      }
    });
  };

  useEffect(() => {
    updateDirection(window.innerWidth);

    window.addEventListener("resize", (e) => {
      let width = e.target.innerWidth;
      updateDirection(width);
    });
  }, []);

  return (
    <div className={style.slider}>
      <ul>
        {services.map(({ title }, index) => (
          <ServicesListItem
            key={title}
            title={title}
            active={index === activeIndex}
            onClick={() => {
              setActiveIndex(index);
            }}
          />
        ))}
      </ul>
      <div className={style.body}>
        <ul
          style={{ transform: `translate${direction}(-${activeIndex * 100}%)` }}
        >
          {services.map(({ body }, index) => (
            <ServicesItem
              active={index === activeIndex}
              body={body}
              key={body}
              top={`${index * 100}%`}
            />
          ))}
        </ul>
      </div>
    </div>
  );
}

export default ServicesSlider;
