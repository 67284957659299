import React, { useState, useEffect } from "react";
import SectionHeading from "../SectionHeading/SectionHeading";
import whotImage from "../../assets/whot.png";
import cryptoImage from "../../assets/crypto.png";
import Project from "../Project/Project";
import Button from "../Button/Button";
import Currently from "../Currently/Currently";
import ProjectSkeleton from "../ProjectSkeleton/ProjectSkeleton";
import { AxiosCont } from "../../constants/AxiosCont";

function Projects() {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    AxiosCont.get("/get-project").then((results) => {
      const result = results.data.data;
      setProjects(
        result.map((project) => ({
          ...project,
          roles: JSON.parse(project.roles),
          technologies: JSON.parse(project.technologies_json),
        }))
      );
      console.log(result);
      setLoading(false);
    });
  }, []);

  return (
    <section className="projects" id="projects">
      <SectionHeading>PRΘJECTS</SectionHeading>
      <p className="talk">
      In addition to producing stunning crafts, I have also been working on a range of 
      innovative projects that enhance daily life for humans and contribute to the greater good of society. Here are a few examples of my recent work:
      
      </p>
      <section className="projects-container">
        {loading ? (
          <>
            <ProjectSkeleton />
            <ProjectSkeleton />
          </>
        ) : (
          projects.map((project, index) => (
            <Project project={project} key={project.title} index={index} />
          ))
        )}
      </section>
      <Button className="btn" to={"/archive"} isLink>
        SEE MORE
      </Button>
      <Currently className="currently" />
    </section>
  );
}

export default Projects;
