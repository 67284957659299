import React, { useEffect, useState } from "react";
import Button from "../Button/Button";
import HeroImage from "../HeroImage/HeroImage";
import Nav from "../Nav/Nav";
import Shapes from "../Shapes/Shapes";
import Typer from "../Typer/Typer";

function Hero() {

  const [json, setJson] = useState([
    { language: "English", content: `Welcome to my portfolio!

  🚀 I'm Chimdi, a Full-Stack + Web3 Developer from Nigeria.
  
  💡 Creativity and innovation drive me to solve complex challenges.
  
  🌐 Expert in backend (Node.js & Python) and frontend/mobile app development.
  
  ✨ I create exceptional digital experiences that exceed expectations.
  
  🎉 Thinking outside the box, I deliver unprecedented results.
  
  🏆 My solutions have helped businesses achieve their goals.
  
  🌟 Let's collaborate and create something extraordinary!
  
  🤝 Contact me today to get started!` },
  {
    language:"Pigin",
    content:`Welcome to my portfolio!

    🚀 I be Chimdi, a Full-Stack + Web3 Developer from Naija.
    
    💡 I dey use creativity and innovation solve complex wahala.
    
    🌐 I sabi backend (Node.js & Python) and frontend/mobile app development well-well.
    
    ✨ I dey create digital experiences wey pass expectations.
    
    🎉 I dey think outside the box, deliver results wey no be for here.
    
    🏆 My solutions don help businesses achieve their goals.
    
    🌟 Make we join hand, create something wey burst brain!
    
    🤝 Holla me sharp-sharp make we start!`
  },{
    language:"Spanish",
    content:`¡Bienvenido a mi portafolio!

    🚀 Soy Chimdi, un desarrollador Full-Stack + Web3 de Nigeria.
    
    💡 La creatividad y la innovación me impulsan a resolver desafíos complejos.
    
    🌐 Experto en desarrollo backend (Node.js y Python) y frontend/aplicaciones móviles.
    
    ✨ Creo experiencias digitales excepcionales que superan las expectativas.
    
    🎉 Pensando de forma innovadora, entrego resultados sin precedentes.
    
    🏆 Mis soluciones han ayudado a las empresas a alcanzar sus objetivos.
    
    🌟 ¡Colaboremos y creemos algo extraordinario!
    
    🤝 ¡Contáctame hoy mismo para comenzar!`
  },{
    language:"French",
    content:`Bienvenue dans mon portfolio !

    🚀 Je suis Chimdi, un développeur Full-Stack + Web3 du Nigeria.
    
    💡 La créativité et l'innovation me poussent à résoudre des défis complexes.
    
    🌐 Expert en développement backend (Node.js et Python) et frontend/applications mobiles.
    
    ✨ Je crée des expériences numériques exceptionnelles qui dépassent les attentes.
    
    🎉 En sortant des sentiers battus, j'obtiens des résultats sans précédent.
    
    🏆 Mes solutions ont aidé les entreprises à atteindre leurs objectifs.
    
    🌟 Collaborons et créons quelque chose d'extraordinaire !
    
    🤝 Contactez-moi dès aujourd'hui pour commencer !`
  },{
    language:"Igbo",
    content:`Nnọọ n'ime nke mkpọchi m!

    🚀 A hụ Chimdi, ọkụkọ nke Full-Stack + Web3 Developer site n'Nigeria.
    
    💡 Mkpaghara na nkiri ndi mkpa ekpere ahụ, na-abịa n'azụpụta ndị ndụ mmadụ.
    
    🌐 Mmalite n'ọkọlọtọ (Node.js & Python) na nchekwa akụkọlụ/akụkọlụ mmepụta oge.
    
    ✨ A ga-eme digital ndị ozuzu nwere ike ihe ọ bụla na-agbakọrịrị ahụ na ọzọ.
    
    🎉 Na-emepụtara ihe ọzọ nkiri elu, a ga-ebịa nkwụsịọ dị elu.
    
    🏆 Nkọwa mmepụta m jiri gị n'akụkụ ndụ ndị gosipụtara iri n'ibi.
    
    🌟 Ka anyị rụọ ọrụ na-arụ ụwa nke ọ bụla!
    
    🤝 Bịa ka anyị gaa ụgbọ mmiri a ka anyị bụ nke a!`
  },{
    language:"Yourba",
    content:`Ẹ ku abọ̀ sílẹ̀ fún akọ̀silẹ̀ mi!

    🚀 Mo ni Chimdi, olùṣẹ̀gun Full-Stack + Web3 lati Orilẹ̀-èdè Nàìjíríà.
    
    💡 Oyinlẹ̀ ati ibikibi ma ran mi lọ si ṣaṣeyẹ̀ ìdíyelé àgbára.
    
    🌐 Àgbára ni mi ni irinṣẹ̀ (Node.js & Python) ati ibikibi/awọn ẹ̀rọ ìpamọ.
    
    ✨ Mo ṣe ṣiṣe ìdíyelé diẹ sìlẹ̀ ti o wọlé, tó bẹ̀rẹ̀ niwájú ìhàdúrà.
    
    🎉 Nipa atunṣẹ̀ àgbára, mo yipada awọn ìṣe tí kò lè mọ̀.
    
    🏆 Awọn ibere mi ti ṣe ni lóríléèṣẹ̀ awọn asopọ lati lọ́ si ìlànà-ọ̀rọ̀ wọn.
    
    🌟 Jọ̀wọ́ ṣe ìṣẹ́ pamo pẹ̀lú mi, jẹ́ ká ṣe ọ̀rọ̀ tí a kò lè wà láìrò!
    
    🤝 Sọ́ mi lẹ́yìn ni yọ kókó lẹ́yìn!`
  },{
    language:"Hausa",
    content:`Maraba zuwa zuwa zuwa zuwa daga cikin ƙididdiga na!

    🚀 Ina Chimdi, mai kula da tsarin rubuce-rubuce + mai rubuta ƙarin Web3 daga Nijeriya.
    
    💡 Kula da ƙayan kula da haɗin kai nayi sa shawara masu bincike masu girma.
    
    🌐 Mai hankali a backend (Node.js & Python) da frontend/cibiyar sadarwa/mai amfani na garkuwa.
    
    ✨ Ina yi ƙarin nazari akan tsarin watsa labarin digital da ke cikin kwanaki.
    
    🎉 Wanda ya sha gida daga yatsun idanu, na yi ƙarin gwaji.
    
    🏆 Tsarin dake nuna watsa labarin na ke farfajiyar yanayin kasuwanci su gina abubuwan su.
    
    🌟 Yi zama kamar wani kudade da yi ƙarin shawara!
    
    🤝 Ziyarci ni yau don a fara!`
  }]);

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex(prevIndex => (prevIndex + 1) % json.length);
    }, 6500);

    return () => clearInterval(timer);
  }, [json]);

  return (
    <section className="hero">
      <Nav />
      <Typer />
      <section className="grid">
        <div className="talk">
          <h1>
            <span>Creative and Talented </span>
            Full-Stack + Web3 Developer
          </h1>
          {/* <p className="subtitle">
          Welcome to my portfolio, where creativity meets innovation! My name is Chimdi, a full-stack + web3 developer based in Nigeria. I specialize in crafting unique and imaginative solutions that solve complex challenges.

With my expertise in backend development using Node.js and Python, coupled with my extensive knowledge in frontend and mobile app development, I create exceptional digital experiences that exceed expectations.

My portfolio showcases my ability to think outside the box and deliver exceptional solutions that have helped businesses achieve their goals. Let's work together to craft something extraordinary. Contact me today and let's get started!
          </p> */}
          <p className="subtitle">
            Language: {json[currentIndex].language}
          </p>
          <p className="subtitle">
          {json[currentIndex].content}
          </p>
          <Button href={"#projects"}>SEE MY WORK</Button>
        </div>
        <div className="image">
          <figure>
            <HeroImage />
          </figure>
        </div>
      </section>
      <Shapes />
    </section>
  );
}

export default Hero;
